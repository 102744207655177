import portfolio1 from "./portfolio1.png";
import portfolio2 from "./portfolio2.png";
import portfolio3 from "./portfolio3.png";
import portfolio4 from "./portfolio4.png";
import portfolio5 from "./portfolio5.png";
import portfolio6 from "./portfolio6.png";
import portfolio7 from "./portfolio7.png";
import portfolio8 from "./portfolio8.png";
import portfolio9 from "./portfolio9.png";
import portfolio10 from "./portfolio10.png";
import portfolio11 from "./portfolio11.png";
import portfolio12 from "./portfolio12.png";

const logotext = "Gyasi Labz";
const meta = {
    title: "Gyasi Labz",
    description: "web3 degen coder & creative",
};

const introdata = {
    title: "Gyasi Labz",
    animated: {
        first: "MetaPhysical Art Institute",
        second: "Metaverse Creator",
        third: "ReactJS",
        fourth: "Blender",
        
    },
    description: "Let me build your imaginations",
    your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
    title: "The Bio",
    aboutme: "Gyasi is a 32-year-old creator, 3D artist and tech enthusiast based in Prattville, Alabama. Born and raised in Nashville, TN, he has always had a passion for pushing boundaries with innovative new ideas. He's the founder of the MetaPhysical Art Institute DAO and an active member of the award winning web3 crew Team W3BBIE. A master of Blender 3D software, Gyasi is also skilled at building ReactJS web apps. His creativity and technical know-how make him a leader in his field.",
};
const worktimeline = [{
        jobtitle: "Valiant Cross 'The African American Male Experience' Museum.",
        where: "Montgomery, AL",
        date: "2022",
    },
    {
        jobtitle: "Thirdweb Hackathon w/ Team W3BBIE",
        where: "interwebs",
        date: "2023",
    },
    {
        jobtitle: "Love, Kris Lloyd",
        where: "Montgomery, AL",
        date: "2023",
    },
];

const skills = [{
        name: "ReactJS",
        value: 80,
    },
    {
        name: "Photoshop",
        value: 95,
    },
    {
        name: "Javascript",
        value: 60,
    },
    {
        name: "NodeJS",
        value: 70,
    },
    {
        name: "Blender",
        value: 85,
    },
];

const services = [{
        title: "UI & UX Design",
        description: "Let me build your company's next web app.",
    },
    {
        title: "Metaverse Creator",
        description: "Let's create your space virtually; using Unity & Spatial.io.",
    },
    {
        title: "NFT consultant",
        description: "Using my knowledge of the NFT market; let me help you on board your physical art onto the blockchain.",
    },
];

const dataportfolio = [{
        img: portfolio1,
        description: "Bari Botz",
        link: "#",
    },
    {
        img: portfolio2,
        description: "GWOP Token, MAI goverenence token.",
        link: "https://mai-vote-dao.vercel.app",
    },
    {
        img: portfolio3,
        description: "MAI virtual HQ",
        link: "https://www.spatial.io/s/Blue-Lotus-63eef9877d2ecebbfa903b98?share=5741255137410665982",
    },
    {
        img: portfolio4,
        description: "Bari Botz",
        link: "#",
    },
    {
        img: portfolio5,
        description: "MAI virtual HQ",
        link: "https://www.spatial.io/s/Blue-Lotus-63eef9877d2ecebbfa903b98?share=5741255137410665982",
    },
    {
        img: portfolio6,
        description: "MAI",
        link: "https://www.metaphysicalartinstitute.com",
    },

    {
        img: portfolio7,
        description: "Scenario.gg AI self portrait",
        link: "#",
    },
    {
        img: portfolio8,
        description: "Valiant Cross Muesume",
        link: "#",
    },
    {
        img: portfolio9,
        description: "Love, Kris Lloyd",
        link: "https://kris-lloyd-client.vercel.app",
    },
    {
        img: portfolio10,
        description: "GOT GWOP?",
        link: "https://mai-vote-dao.vercel.app",
    },
    {
        img: portfolio11,
        description: "MAI DAO Portal",
        link: "https://metaphysicalart.io",
    },
    {
        img: portfolio12,
        description: "MAI DAO Portal.",
        link: "https://metaphysicalart.io",
    },
];

const contactConfig = {
    YOUR_EMAIL: "metaphysicalartinstitute@gmail.com",
    YOUR_FONE: "(334)717-0083",
    description: "Get at me bro. ",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_awq7779",
    YOUR_TEMPLATE_ID: "template_wvoicac",
    YOUR_USER_ID: "FJsOekD3sO2PNGjq5",
};

const socialprofils = {
    github: "https://github.com/barigyasi",
    facebook: "https://facebook.com/tabari.humphries",
    twitter: "https://twitter.com/gyas_i",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};